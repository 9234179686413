import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/800.css";

// solves the gatsby v5 bug that a sub page routing does not scroll to the top of the page 
// https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1631378720
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};
